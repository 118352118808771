export default function getFavicons(version: number, theme?: string) {
  switch (theme) {
    case 'vision':
      return [
        {
          rel: 'manifest',
          href: '/vision-manifest.json',
        },
        {
          rel: 'icon',
          href: `/favicon/favicon.ico?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `/favicon/favicon-32x32.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '192x192',
          href: `/favicon/android-chrome-192x192.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '512x512',
          href: `/favicon/android-chrome-512x512.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          href: `/favicon/apple-touch-icon.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: `/favicon/apple-touch-icon-57x57.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: `/favicon/apple-touch-icon-60x60.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: `/favicon/apple-touch-icon-72x72.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: `/favicon/apple-touch-icon-76x76.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: `/favicon/apple-touch-icon-114x114.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: `/favicon/apple-touch-icon-120x120.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: `/favicon/apple-touch-icon-144x144.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: `/favicon/apple-touch-icon-152x152.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '167x167',
          href: `/favicon/apple-touch-icon-167x167.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `/favicon/apple-touch-icon-180x180.png?v=${version}`,
        },
      ];

    case 'ascend':
      return [
        {
          rel: 'manifest',
          href: '/ascend-manifest.json',
        },
        {
          rel: 'shortcut icon',
          type: 'image/x-icon',
          href: `/favicon/ascend-favicon.ico?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `/favicon/ascend-favicon-16x16.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `/favicon/ascend-favicon-32x32.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: `/favicon/ascend-favicon-96x96.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '57x57',
          href: `/favicon/ascend-apple-icon-57x57.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '60x60',
          href: `/favicon/ascend-apple-icon-60x60.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '72x72',
          href: `/favicon/ascend-apple-icon-72x72.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '76x76',
          href: `/favicon/ascend-apple-icon-76x76.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '114x114',
          href: `/favicon/ascend-apple-icon-114x114.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '120x120',
          href: `/favicon/ascend-apple-icon-120x120.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '144x144',
          href: `/favicon/ascend-apple-icon-144x144.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '152x152',
          href: `/favicon/ascend-apple-icon-152x152.png?v=${version}`,
        },
        {
          rel: 'apple-icon',
          sizes: '180x180',
          href: `/favicon/ascend-apple-icon-180x180.png?v=${version}`,
        },
        {
          rel: 'android-icon',
          sizes: '192x192',
          href: `/favicon/android-icon-192x192.png?v=${version}`,
        },
      ];

    default:
      return [
        {
          rel: 'manifest',
          href: `/manifest.json?v=${version}`,
        },
        {
          rel: 'icon',
          href: `/favicon/sword/favicon.ico?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `/favicon/sword/favicon-16x16.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `/favicon/sword/favicon-32x32.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: `/favicon/sword/favicon-32x32.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '36x36',
          href: `/favicon/sword/android-icon-36x36.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '48x48',
          href: `/favicon/sword/android-icon-48x48.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '72x72',
          href: `/favicon/sword/android-icon-72x72.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: `/favicon/sword/android-icon-96x96.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '144x144',
          href: `/favicon/sword/android-icon-144x144.png?v=${version}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '192x192',
          href: `/favicon/sword/android-icon-192x192.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          href: `/favicon/sword/apple-icon.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: `/favicon/sword/apple-icon-57x57.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: `/favicon/sword/apple-icon-60x60.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: `/favicon/sword/apple-icon-72x72.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: `/favicon/sword/apple-icon-76x76.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: `/favicon/sword/apple-icon-114x114.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: `/favicon/sword/apple-icon-120x120.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: `/favicon/sword/apple-icon-144x144.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: `/favicon/sword/apple-icon-152x152.png?v=${version}`,
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `/favicon/sword/apple-icon-180x180.png?v=${version}`,
        },
      ];
  }
}
