import { StyledContent } from './styles';

export default function TagContent({
  children,
  component: Component,
  tagName = 'p',
  ...rest
}: any) {
  const tagContent = typeof Component?.target === 'string' ? Component.target : tagName;

  return (
    <StyledContent as={Component} $tagContent={tagContent} {...rest}>
      {children}
    </StyledContent>
  );
}
