import styled, { css } from 'styled-components';

interface PaddingType {
  top?: number | string;
  bottom?: number | string;
}

interface WrapperType {
  $paddingMd?: PaddingType;
  $paddingSm?: PaddingType;
  $paddingXs?: PaddingType;
}

const Wrapper = styled.section<WrapperType>`
  padding: 136px 0;

  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `};

  ${(props) =>
    props.$paddingMd?.top &&
    css`
      padding-top: ${props.$paddingMd.top}px;
    `};

  ${(props) =>
    props.$paddingMd?.bottom &&
    css`
      padding-bottom: ${props.$paddingMd.bottom}px;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 80px 0;

    ${(props) =>
      props.$paddingSm?.top &&
      css`
        padding-top: ${props.$paddingSm.top}px;
      `};

    ${(props) =>
      props.$paddingSm?.bottom &&
      css`
        padding-bottom: ${props.$paddingSm.bottom}px;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 52px 0;

    ${(props) =>
      props.$paddingXs?.top &&
      css`
        padding-top: ${props.$paddingXs.top}px;
      `};

    ${(props) =>
      props.$paddingXs?.bottom &&
      css`
        padding-bottom: ${props.$paddingXs.bottom}px;
      `};
  }
`;

export default Wrapper;
