import React, { PropsWithChildren, useMemo } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import remarkGFM from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import { getUrlAttributes } from '@/utils/useUrlAttributes';

type MarkdownRichTextProps = Pick<Options, 'children' | 'rehypePlugins'> & {
  components?: Options['components'];
  elements?: Options['allowedElements'];
};

const MarkdownRichText: React.FC<MarkdownRichTextProps> = ({
  components = {},
  elements = [],
  rehypePlugins = [],
  children,
  ...rest
}) => {
  const finalElements = useMemo(
    () => ['b', 'strong', 'sub', 'sup', ...(elements || [])],
    [elements],
  );
  const finalRehypePlugins = useMemo(
    () => [rehypeRaw, ...(rehypePlugins as [])] as Options['rehypePlugins'],
    [rehypePlugins],
  );
  const finalComponents = useMemo(
    () => ({
      ...components,
      // eslint-disable-next-line no-unused-vars
      a: ({ node, href, ...props }: PropsWithChildren & { node: any; href: string }) => {
        const attributes = getUrlAttributes(href);

        return (
          <a {...attributes} href={href}>
            {props.children}
          </a>
        );
      },
    }),
    [components],
  );

  if (!children) return null;

  return (
    <ReactMarkdown
      {...rest}
      remarkPlugins={[[remarkGFM, remarkBreaks]]}
      rehypePlugins={finalRehypePlugins}
      unwrapDisallowed
      allowedElements={finalElements}
      components={finalComponents as MarkdownRichTextProps['components']}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownRichText;
