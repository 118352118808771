import styled from 'styled-components';
import theme from '@/utils/styles-variables';

export const ItsTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const ItsTimeBorder = styled.div`
  border-radius: 500px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    box-shadow: 0px 4px 12px rgba(221, 212, 204, 0.5);
    background: linear-gradient(#ffffff 0%, #ddd4cc 100%);
    padding: 2px;
    max-width: 464px;
  }
  @media (min-width: ${theme.breakpoints.min_lg}px) {
    max-width: 547px;
  }
`;
export const ItsTimeContent = styled.div`
  width: 100%;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    background-color: ${theme.colors.neutral.dark};
    padding: 12px 12px 12px 32px;
    justify-content: space-between;
    max-width: 464px;
  }
  @media (min-width: ${theme.breakpoints.min_lg}px) {
    max-width: 547px;
    padding: 16px 16px 16px 48px;
  }
`;
export const ItsTimeText = styled.p`
  display: none;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    color: ${theme.colors.grey[800]};
    font-family: ${theme.fonts.title};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: block;
  }
  @media (min-width: ${theme.breakpoints.min_lg}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;
export const ItsTimeLink = styled.div`
  display: flex;
  width: 199px;
  height: 48px;
  background: ${theme.colors.primary.default};
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fonts.title};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white};

  svg,
  path {
    fill: ${theme.colors.white};
    margin-left: 8px;
  }

  @media (min-width: ${theme.breakpoints.min_md}px) {
    background: ${theme.colors.white};
    color: ${theme.colors.grey[800]};

    svg,
    path {
      fill: ${theme.colors.grey[800]};
    }
  }
`;
