import styled from 'styled-components';

import Button from './Button';
import theme from '@/utils/styles-variables';

const RedButton = styled(Button).attrs({
  loadingColor: theme.colors.white,
})`
  background-color: ${(props) => props.theme.colors.primary.default};
  color: ${(props) => props.theme.colors.white};
  text-align: center;

  &:hover {
    background: ${(props) => props.theme.colors.primary.dark};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.grey.light};
    pointer-events: none;
    color: ${(props) => props.theme.colors.grey.default};
  }
`;

export default RedButton;
