import React, { useContext } from 'react';
import Link from 'next/link';

import { Wrapper, Content, UpperLabel, CustomTitle, TextArrowButton } from './styles';

import EventContext from '@/contexts/event';
import { TagContent } from '@/components/hackathon/TagContent';
import { Paragraph } from '@/components/core';

export default function SectionHeader({
  centered = false,
  sizeLarge = '100%',
  withTagNames = false,
}) {
  const { event } = useContext(EventContext);
  const { upperLabel, title, label, link, buttonLabel } = event;

  return (
    <Wrapper $sizeLarge={sizeLarge} $centered={centered}>
      <Content>
        <UpperLabel $type="primary" as="p">
          {upperLabel}
        </UpperLabel>
        <CustomTitle
          type="primary"
          as={withTagNames ? TagContent : undefined}
          tagName="h2"
          forwardedAs="h2"
        >
          {title}
        </CustomTitle>
        {label && <Paragraph as={withTagNames ? TagContent : undefined}>{label}</Paragraph>}
        {link && buttonLabel && (
          <Link href={link} legacyBehavior>
            <TextArrowButton>{buttonLabel ?? 'Join'}</TextArrowButton>
          </Link>
        )}
      </Content>
    </Wrapper>
  );
}
