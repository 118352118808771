import theme from '@/utils/styles-variables';
const wysiwyg = () => {
  return `
          font-family: ${theme.fonts.body};
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          
          strong {
            font-weight: 700;
          }
          
          em {
            font-style: italic;
          }
          
          del {
            text-decoration: line-through;
          }
          p {
            font-family: ${theme.fonts.body};
          }
          h3 {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 20px;
            font-family: ${theme.fonts.body};

            span {
              color: #FFFFFF;
            }
          }
          
          h4 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 18px;
            font-family: ${theme.fonts.body};
          }
          
          h5 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 18px;
            font-family: ${theme.fonts.body};
          }
          
          h6 {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 18px;
            text-transform: uppercase;
            font-family: ${theme.fonts.body};
          }
  
          a {
              display: inline;
              color: #FFFFFF;
              cursor: pointer;
              text-decoration: underline;
              font-family: ${theme.fonts.body};
          }
          
          blockquote {
            display: block;
            margin: 0;
            border-left: 2px solid #F46262;
            padding-left: 25px;
          }
          
          ol,
          ul {
              display: block;
              margin: 0;
          }
          
          ul {
              li {
                  list-style-type: none;
                  padding-left: 40px;
                  position: relative;
                  
                  &:not(:last-child){
                    margin-bottom: 15px;
                  }
                  
                  &::before {
                    position:absolute;
                    left: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                    content: "";
                    background-image: url("/assets/checkmark.svg");
                }
              }
          }
          
          ol {
              li {
                  list-style-type: decimal;
                  margin-left: 20px;
                  
                  &:not(:last-child){
                    margin-bottom: 15px;
                  }
              }
          }
   
        code {
          display: block;
          font-family: ${theme.fonts.body};
          padding: 20px;
          background-color: #F7F4F2;
        }
         
      `;
};

export { wysiwyg };
