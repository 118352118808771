import styled from 'styled-components';
import Button from './Button';
import theme from '@/utils/styles-variables';

const SecondaryButton = styled(Button).attrs({
  loadingColor: theme.colors.white,
})`
  border: 1px solid var(--secondary-button-border, ${(props) => props.theme.colors.grey.dark});
  cursor: pointer;

  &:hover {
    color: var(--secondary-button-hover-color, ${(props) => props.theme.colors.white});
    background-color: var(--secondary-button-hover-bg, ${(props) => props.theme.colors.grey.dark});
  }
  &:disabled {
    pointer-events: none;
    background-color: #e7eaf0;
    color: #747986;
  }
`;

export default SecondaryButton;
