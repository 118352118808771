export function getImgSrcWorkaround(src, updatedAt) {
  try {
    // if image src is a valid url return as is
    const urlObject = new URL(src);

    if (!updatedAt) {
      return src;
    }

    urlObject.searchParams.set('updatedAt', updatedAt || '');

    return urlObject.toString();
  } catch {
    if (process.env.NEXT_PUBLIC_LOCALHOST_IMAGE_DOMAIN) {
      return process.env.NEXT_PUBLIC_LOCALHOST_IMAGE_DOMAIN + src;
    }

    return src;
  }
}

export function getImgData(image) {
  if (!image) {
    return null;
  }

  if (image.data === null) {
    return null;
  }

  if (image?.data?.attributes) {
    return { image, ...image.data.attributes };
  }

  if (image?.attributes) {
    return { image, ...image.attributes };
  }

  return image;
}
