import React, { useState, createContext, useEffect } from 'react';
import moment from 'moment';

const EventContext = createContext();

function canItJoin(canJoin, content) {
  const today = moment.utc();
  const eventStartDate = moment.utc(content?.date);
  const eventEndDate = moment.utc(content?.endDate);

  return !today >= eventStartDate && today <= eventEndDate && canJoin;
}

export const EventProvider = ({ children, content }) => {
  const [canJoin, setCanJoin] = useState(
    content?.info ? canItJoin(content?.info.canJoin, content.info) : false,
  );
  const [status, setStatus] = useState(null);

  function verifyEventState() {
    const now = new Date();
    const target = new Date(content.info.date);
    const targetEnd = new Date(content.info.endDate);
    targetEnd.setDate(targetEnd.getDate() + 1);

    const difference = target.getTime() - now.getTime();
    const differenceEnd = targetEnd.getTime() - now.getTime();

    if (difference > 0 && differenceEnd > 0) {
      setStatus('before');
      setCanJoin(false);
    } else if (difference < 0 && differenceEnd > 0) {
      setStatus('during');
      setCanJoin(content.info.canJoin);
    } else if (differenceEnd < 0) {
      setStatus('after');
      setCanJoin(false);
    }
  }

  useEffect(() => {
    verifyEventState();

    const timerInterval = setInterval(() => {
      verifyEventState();
    }, 1000);

    return () => clearTimeout(timerInterval);
  }, []);

  return (
    <EventContext.Provider
      value={{
        canJoin,
        content: content,
        info: content.info,
        header: content.header,
        event: content.event,
        venue: content.venue,
        agenda: content.agenda,
        agendaEntries: content.agendaEntries,
        moreInfo: content.moreInfo,
        moreInfoFaqs: content.moreInfoFaqs,
        joinRemotely: content?.joinRemotely,
        hideJoin: content?.joinRemotely?.hideJoin,
        status,
        keynoteSpeaker: content.keynote,
        judgesList: content.judgesList,
        video: content.video,
        themeEvent: content.theme,
        logo: content.logo,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
