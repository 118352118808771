import { RemoveScroll } from 'react-remove-scroll';
import ReactModal from 'react-modal';
import React from 'react';

type ModalType = {
  children?: React.ReactNode;
  style?: any;
  isOpen?: boolean;
  onRequestClose?: any;
};

const defaultStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '100000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    overflow: 'initial',
    backgroundColor: 'transparent',
    padding: '0',
  },
};

export default function Modal({
  children,
  style,
  isOpen = false,
  onRequestClose,
  ...props
}: ModalType) {
  return (
    <RemoveScroll enabled={isOpen} removeScrollBar={false}>
      <ReactModal
        style={style || defaultStyle}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        {...props}
      >
        {children}
      </ReactModal>
    </RemoveScroll>
  );
}
