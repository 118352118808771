import React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import LogoSword from '@/public/assets/logo/sword_logo__red_white.svg';
import LogoSwordWhite from '@/public/assets/logo/sword_logo__white.svg';
import { Container } from '@/components/core';

type WrapperStyledType = {
  $absolute?: boolean;
  $darkTheme?: boolean;
};

export type FooterType = {
  absolute?: boolean;
  darkTheme?: boolean;
};

const Footer = ({ absolute = false, darkTheme }: FooterType) => {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper $absolute={absolute} $darkTheme={darkTheme}>
      <Container>
        <Row>
          <label>© {currentYear} Sword Health, Inc. All Rights Reserved</label>

          <Link href="https://swordhealth.com/" target="_blank">
            {absolute || darkTheme ? <LogoSwordWhite /> : <LogoSword />}
          </Link>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div<WrapperStyledType>`
  width: 100%;
  padding-bottom: 48px;
  background-color: ${({ theme }) => theme.colors.grey.dark};

  svg {
    width: 114px;
    height: 28px;
  }

  ${(props) =>
    props.$absolute &&
    css`
      background: transparent;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-top: 48px;
      z-index: 2;
    `};

  ${(props) =>
    props.$darkTheme &&
    css`
      background: transparent;
      padding-top: 48px;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 24px;
  }
`;

const Row = styled.div`
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.grey.light};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  a {
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 16px;
  }
`;
