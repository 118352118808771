import { AnchorHTMLAttributes, MouseEvent, useCallback, useEffect, useState } from 'react';
import navigateToId from './navigation';
import { useReducedMotion } from './useReducedMotion';

export function getUrlAttributes(
  url: string,
  // eslint-disable-next-line no-unused-vars
  clickAnchor?: (e: MouseEvent<HTMLAnchorElement>) => void,
) {
  try {
    const isAnchor = url.startsWith('#');

    if (isAnchor && clickAnchor) {
      return {
        onClick: clickAnchor,
      };
    }

    const isExternal = new URL(url).hostname !== window.location.hostname;

    if (isExternal) {
      return { target: '_blank', rel: 'noopener noreferrer' };
    }

    return {};
  } catch {
    return {};
  }
}

export function useUrlAttributes(url: string) {
  const prefersReducedMotion = useReducedMotion();
  const [attributes, setAttributes] = useState<AnchorHTMLAttributes<HTMLAnchorElement>>({});

  const clickAnchor = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigateToId(url, prefersReducedMotion);
    },
    [prefersReducedMotion, url],
  );

  useEffect(() => {
    if (!window || !url) {
      return;
    }

    const attributes = getUrlAttributes(url, clickAnchor);

    setAttributes(attributes);
  }, [clickAnchor, url]);

  return attributes;
}
