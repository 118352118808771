import styled from 'styled-components';

const Container = styled.div`
  max-width: 1336px;
  padding: 0 36px;
  margin: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0 24px;
  }
`;

export default Container;
