import { useCallback, useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';

import { Wrapper, BannerBox, CloseButton } from './styles';
import CloseSvg from '@/public/assets/circle-close.svg';
import getCookieData from '@/utils/getCookieParams';
import ExternalLink from '../ExternalLink';

const COOKIES_KEY_REMOVED = 'BANNER_REMOVED';
const { path, domain } = getCookieData();

const Banner = ({ bannerInfo }) => {
  const { text, textLink, updatedAt } = bannerInfo;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const removed = getCookie(COOKIES_KEY_REMOVED, { path, domain });

    if (!removed || (removed && removed !== updatedAt)) setIsVisible(true);
  }, []);

  const closeBanner = useCallback(() => {
    setCookie(COOKIES_KEY_REMOVED, updatedAt, getCookieData());
    setIsVisible(false);
  }, [updatedAt]);

  if (!isVisible) {
    return null;
  }

  return (
    <Wrapper>
      <BannerBox>
        <div>
          <span>{text}</span>
          <ExternalLink href={textLink.href}>{textLink.label}</ExternalLink>
        </div>
        <CloseButton onClick={closeBanner}>
          <CloseSvg />
        </CloseButton>
      </BannerBox>
    </Wrapper>
  );
};

export default Banner;
