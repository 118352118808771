import styled from 'styled-components';
import { Title } from '@/components/core/Title';

const CustomTitle = styled(Title)`
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-weight: 700;
  font-size: 45px;
  line-height: 56px;
  padding-bottom: 16px;
  max-width: 640px;
  font-family: ${(props) => props.theme.fonts.title};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export default CustomTitle;
