import styled from 'styled-components';
import { Title, UpperText } from '@/components/core/Title';
import { WhiteButton } from '@/components/core/Button';

export const Header = styled.header`
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 16px;
  }
`;
export const UpperLabel = styled(UpperText)`
  opacity: 1;
  letter-spacing: 2px;
  font-weight: 700;
  padding-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding-bottom: 16px;
  }
`;
export const CustomTitle = styled(Title)`
  font-size: 45px;
  line-height: 56px;
  padding-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;
export const CustomTitleVideo = styled(CustomTitle)`
  font-size: 30px;
  line-height: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 0 24px 16px;
  }
`;
export const ContentVideo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  border-radius: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    aspect-ratio: 4/5;
    border-radius: 16px;
  }
`;
export const VideoOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.grey.dark};
  opacity: 0.6;
  border-radius: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    aspect-ratio: 4/5;
    border-radius: 16px;
  }
`;
export const ActionVideo = styled.div`
  position: absolute;
  width: 100%;
  z-index: 3;
  text-align: center;
`;
export const ButtonPlay = styled(WhiteButton)`
  svg {
    margin-right: 10px;
    transition: fill 120ms ease-in-out;
  }

  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;
