import styled, { css } from 'styled-components';

type NavItemProps = {
  $white?: boolean;
  $button?: boolean;
  $active?: boolean;
};

const NavItem = styled.li<NavItemProps>`
  font-size: 15px;
  line-height: 40px;
  letter-spacing: 0;
  padding: 20px 0 19px;
  transition: all 0.3s ease;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${(props) =>
      props.$white &&
      !props.$button &&
      css`
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      `};
  }

  &.request-demo {
    line-height: 40px;
    height: unset;
  }

  & *[type='button'] {
    cursor: pointer;

    ${(props) =>
      props.$active &&
      css`
        &:before {
          opacity: 1;
        }
      `}
  }

  ${(props) =>
    props.$white &&
    css`
      color: ${(props) => props.theme.colors.grey.dark};
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    &:not(:last-child) {
      margin-right: 32px;
    }

    ${(props) =>
      props.$active &&
      css`
        opacity: 1;
      `}

    & *[type='button'] {
      &:hover {
        &:before {
          opacity: 1;
        }
        opacity: 100%;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 32px 36px;
    position: relative;
    text-align: left;

    & *[type='button'] {
      &:before {
        bottom: 1px;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 1px;
      background-color: rgba(31, 34, 44, 0.1);
      left: 36px;
      bottom: 0;
      right: 36px;
    }

    &.request-demo {
      position: absolute;
      bottom: 0;
      width: 50vw;
      border: none;
      padding-bottom: 48px;
      background-color: #fff;
      button {
        width: 100%;
        text-align: center;
        max-width: 240px;
      }
      &:before {
        display: none;
      }
    }

    &.request-demo {
      right: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    &.request-demo {
      position: fixed;
      padding: 0 24px;
      width: 100vw;
      left: unset;
      right: unset;
      bottom: 24px;
      text-align: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    font-size: 20px;
    line-height: 35px;
    padding: 24px;

    &:before {
      left: 24px;
      right: 24px;
    }
  }
`;

export default NavItem;
