import NextLink, { LinkProps } from 'next/link';
import { useUrlAttributes } from '@/utils/useUrlAttributes';
import { FC, AnchorHTMLAttributes } from 'react';

export type ExternalLinkProps = LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternalLink: FC<ExternalLinkProps> = ({ href, children, ...props }) => {
  const urlAttributes = useUrlAttributes(href);

  return (
    <NextLink href={href} {...props} {...urlAttributes}>
      {children}
    </NextLink>
  );
};

export default ExternalLink;
