import styled from 'styled-components';

type UpperTextProps = {
  $type?: 'primary' | 'secondary';
};

const UpperText = styled.div<UpperTextProps>`
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: var(
    --label-color,
    ${(props) =>
      props.$type === 'primary' ? props.theme.colors.grey.default : props.theme.colors.grey[400]}
  );
  font-family: ${(props) => props.theme.fonts.title};
  padding-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 24px;
  }
`;

export default UpperText;
