import React, { useContext } from 'react';
import Link from 'next/link';
import { RemoveScroll } from 'react-remove-scroll';
import styled, { css } from 'styled-components';

import { RedButton } from '@/components/core/Button';
import NavigationContext from '@/contexts/navigation';
import EventContext from '@/contexts/event';
import { Container } from '@/lib/flexbox';

import Hamburguer from './Burguer';
import LogoButton from './Logo';
import { MenuList } from './List';
import { StrapiImage } from '@/types/strapi';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

export type NavigationType = {
  customLogo?: StrapiImage;
  darkTheme?: boolean;
  fixed?: boolean;
  showAgenda?: boolean;
};

export function navigateToId(hash: string) {
  const id = hash.replace('#', '');
  const element = document.getElementById(id);
  if (element && window) {
    window.scrollTo({
      behavior: 'smooth',
      top: element.offsetTop - 100,
    });

    window.history.replaceState(null, '', `/#${hash}`);
  } else if (window) {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    window.history.replaceState(null, '', '/');
  }
}

const Navigation = ({ fixed }: NavigationType) => {
  const { scrolled, isOpen, hover } = useContext(NavigationContext);
  const { canJoin, info } = useContext(EventContext);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);

  return (
    <RemoveScroll enabled={!isDesktop && isOpen}>
      <MenuWrapper $white={scrolled || hover} $isOpen={isOpen} $fixed={fixed}>
        <Container>
          <Menu>
            <MenuItem>
              <LogoButton onClick={() => navigateToId('')} />
            </MenuItem>
            <MenuItem>
              <MenuList canJoin={canJoin} info={info} navigateToId={navigateToId} />
            </MenuItem>
            <MenuItem>
              <Hamburguer />
            </MenuItem>

            {canJoin && (
              <JoinButton open={isOpen}>
                <Link href={info.link} target="_blank">
                  <RedButton>{info.joinButtonLabel}</RedButton>
                </Link>
              </JoinButton>
            )}
          </Menu>
        </Container>
      </MenuWrapper>
    </RemoveScroll>
  );
};

export default Navigation;

type MenuWrapperType = {
  $white?: boolean;
  $fixed?: boolean;
  $isOpen?: boolean;
};

const MenuWrapper = styled.div<MenuWrapperType>`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;

  ${(props) =>
    props.$white &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;
    `};

  ${(props) =>
    props.$fixed &&
    css`
      top: 0;
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        min-height: 60px;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    ${(props) =>
      props.$isOpen &&
      css`
        border-bottom: 1px solid rgba(31, 34, 44, 0.1);
      `};
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.theme.fonts.title};
  justify-content: space-between;
`;

const MenuItem = styled.div`
  &:first-child {
    order: 1;
    width: 15%;
    padding: 19px 0;
  }

  &:nth-child(2) {
    order: 2;
  }

  &:nth-child(3) {
    order: 3;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    line-height: 40px;
    &:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 16px 0;
    z-index: 10000;
    height: 60px;
    &:first-child {
      width: 50%;
      z-index: 10000000;
    }

    &:nth-child(2) {
      width: 50%;
    }
  }
`;

type JoinButtonType = {
  open?: boolean;
};

const JoinButton = styled.div<JoinButtonType>`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    display: unset;
    position: absolute;
    content: '';
    top: 10px;
    right: 84px;
    z-index: 10000;
    opacity: 1;
    transition: opacity 0.3s ease;

    ${(props) =>
      props.open &&
      css`
        opacity: 0;
        pointer-events: none;
      `};
  }
`;
