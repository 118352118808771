import styled from 'styled-components';

export const StyledContent = styled.p<{ $tagContent: string }>`
  &::before,
  &::after {
    content: ${({ $tagContent }) => `"<${$tagContent}>\u00A0"`};
    display: inline;
    font-size: 70%;
    line-height: 1;
    opacity: 0.3;
    vertical-align: super;
  }

  &::after {
    content: ${({ $tagContent }) => `"\u00A0</${$tagContent}>"`};
  }
`;
