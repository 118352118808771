import styled, { css } from 'styled-components';
import RedButton from './RedButton';

const WhiteButton = styled(RedButton)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey[800]};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: var(--white-button-hover-bg, ${(props) => props.theme.colors.grey[800]});
  }
  ${(props) =>
    props.loading &&
    css`
      background-color: var(---white-button-hover-bg, ${(props) => props.theme.colors.grey[800]});
      color: transparent;
      pointer-events: none;
    `}
`;

export default WhiteButton;
