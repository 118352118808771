export default function navigateToId(
  hash: string,
  prefersReducedMotion?: boolean,
  addToHistory = true,
) {
  const id = hash.replace('#', '');

  const section = document.getElementById(id);

  if (!section) return;

  section.scrollIntoView({
    block: 'start',
    behavior: prefersReducedMotion ? 'auto' : 'smooth',
  });

  if (!window || !addToHistory) return;

  window.history.replaceState(null, '', `#${id}`);
}
