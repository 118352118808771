import { StyledAs } from '@/types/styled-components';
import { PropsWithChildren } from 'react';
import Wrapper from './Wrapper';

interface PaddingType {
  top?: number | string;
  bottom?: number | string;
}

interface SectionProps extends StyledAs {
  paddingXs?: PaddingType;
  paddingSm?: PaddingType;
  paddingMd?: PaddingType;
  paddingColoredXs?: PaddingType;
  paddingColoredSm?: PaddingType;
  paddingColoredMd?: PaddingType;
  color?: string;
  lastColored?: boolean;
  anchorId?: string;
  className?: string;
}

const Section = ({
  as,
  paddingXs,
  paddingSm,
  paddingMd,
  paddingColoredXs,
  paddingColoredSm,
  paddingColoredMd,
  color,
  lastColored,
  children,
  anchorId,
  className,
}: PropsWithChildren<SectionProps>) => {
  if (lastColored) {
    return (
      <Wrapper
        as={as}
        $paddingXs={paddingXs}
        $paddingSm={paddingSm}
        $paddingMd={paddingMd}
        id={anchorId}
        className={className}
      >
        <Wrapper
          as="div"
          $paddingXs={paddingColoredXs}
          $paddingSm={paddingColoredSm}
          $paddingMd={paddingColoredMd}
          color={color}
        >
          {children}
        </Wrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      as={as}
      $paddingXs={paddingXs}
      $paddingSm={paddingSm}
      $paddingMd={paddingMd}
      color={color}
      id={anchorId}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

export default Section;
