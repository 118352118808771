import styled, { css } from 'styled-components';

import theme from '@/utils/styles-variables';
import { Button } from '@swordhealth/ui-corporate';

type ButtonProps = {
  $darkTheme?: boolean;
  $smallButton: boolean;
  $largeButton: boolean;
};

export const SliderContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: auto;
  position: relative;

  .slick-slide {
    height: 200px;
  }
  .slick-slider {
    z-index: 2;
  }
  .slick-center {
    height: 200px;
    padding: 0 8px;
  }

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 85%;
    padding: 0 32px;

    .slick-center {
      width: 100%;
      height: 320px;
      margin: 0;
    }
    .slick-slide {
      width: 100%;
      height: 320px;
      padding: 0 12px;
    }
    .slick-list {
      border-radius: 16px;
    }
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    max-width: 1130px;
    padding: 0 40px;

    .slick-center {
      height: 500px;
    }
    .slick-slide {
      height: 500px;
    }
  }
`;

export const SliderCard = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 100%;
    height: 320px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    height: 500px;
  }
`;

export const SliderImage = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 8px;

  img {
    border-radius: 8px;
    object-fit: cover;
  }

  @media (min-width: ${theme.breakpoints.min_md}px) {
    width: 100%;
    height: 320px;
    border-radius: 16px;

    img {
      border-radius: 16px;
    }
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    height: 500px;
  }
`;
export const getArrowCSS = ({ $darkTheme }: ButtonProps) => css`
  background-color: transparent;
  color: ${$darkTheme ? 'white' : theme.colors.grey[800]};
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid currentColor;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &::before {
    content: none;
  }

  &:focus {
    color: ${$darkTheme ? 'white' : theme.colors.grey[800]};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const NextArrow = styled(Button).attrs({ variant: 'secondary', rounded: true })<ButtonProps>`
  ${(props) => getArrowCSS(props)}

  right: -62px;

  ${(props) =>
    props.$smallButton &&
    css`
      @media (min-width: ${theme.breakpoints.min_lg}px) {
        right: -66px;
      }
    `}
  ${(props) =>
    props.$largeButton &&
    css`
      @media (min-width: ${theme.breakpoints.min_lg}px) {
        width: 64px;
        height: 64px;

        svg {
          width: 24px;
          height: 24px;
        }

        right: -92px;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
        right: -100px;
      }
    `}
`;

export const PrevArrow = styled(Button).attrs({ variant: 'secondary', rounded: true })<ButtonProps>`
  ${(props) => getArrowCSS(props)}

  left: -62px;

  & svg {
    transform: rotate(180deg);
  }

  ${(props) =>
    props.$smallButton &&
    css`
      @media (min-width: ${theme.breakpoints.min_lg}px) {
        left: -66px;
      }
    `}
  ${(props) =>
    props.$largeButton &&
    css`
      @media (min-width: ${theme.breakpoints.min_lg}px) {
        width: 64px;
        height: 64px;

        svg {
          width: 24px;
          height: 24px;
        }

        left: -92px;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
        left: -104px;
      }
    `}
`;

export const SliderCicleOne = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  position: absolute;
  bottom: -40px;
  left: -10px;
  background-color: ${theme.colors.neutral.default};
  z-index: 1;
  display: none;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    display: block;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 200px;
    height: 200px;
    bottom: -60px;
    left: -50px;
  }
`;
export const SliderCicleTwo = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 100%;
  position: absolute;
  top: -29px;
  right: -1px;
  background-color: ${theme.colors.secondary.light};
  z-index: 1;
  display: none;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    display: block;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    width: 140px;
    height: 140px;
    top: -44px;
    right: -40px;
  }
`;
