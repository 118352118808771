import styled from 'styled-components';
import Image from 'next/image';

import { getImgSrcWorkaround } from '@/utils/images';

type Company = {
  url: string;
  alternativeText: string;
  width: number;
  height: number;
};

type CompaniesProps = {
  list: Company[];
};

const getWidth = (width: number, height: number) => {
  return (width * 48) / height;
};

const Companies = ({ list }: CompaniesProps) => {
  return (
    <>
      {list?.map((company) => {
        const width = getWidth(company.width, company.height);

        return (
          <Wrapper key={company.url} style={{ width }}>
            <Image
              data-testid="companies-image"
              src={getImgSrcWorkaround(company.url)}
              alt={company.alternativeText || ''}
              width={width}
              height={48}
              quality={100}
            />
          </Wrapper>
        );
      })}
    </>
  );
};

const Wrapper = styled.div`
  height: 48px;
  margin-left: 88px;

  img {
    object-fit: contain;
  }

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 40px;
  }
`;

export default Companies;
