import { useContext } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import NavigationContext from '@/contexts/navigation';
import { StyledAs } from '@/types/styled-components';

import NavItem from './NavItem';

type PropsListItem = StyledAs & {
  label?: string;
  url?: string;
  extraClass?: any;
  component?: any;
  button?: boolean;
  onClick?: Function;
  href?: string;
};

const ListItem = ({
  label,
  url,
  extraClass,
  component,
  button,
  onClick,
  ...rest
}: PropsListItem) => {
  const router = useRouter();
  const { setIsOpen, scrolled, hover } = useContext(NavigationContext);

  const Component = component || 'button';

  return (
    <NavItem
      className={cn(extraClass)}
      $active={url === router.pathname}
      $white={scrolled || hover}
      $button={button}
    >
      <>
        <Component
          type="button"
          onClick={async () => {
            setIsOpen(false);
            onClick && onClick();
          }}
          {...rest}
        >
          {label}
        </Component>
      </>
    </NavItem>
  );
};

export default ListItem;
