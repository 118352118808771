import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { Banner } from '@/components/core';
import Footer from '@/components/core/Footer';
import DefaultNavigation from '../Navigation';

import type { NavigationType } from '../Navigation';
import NavigationContext from '@/contexts/navigation';
import { FooterType } from '../Footer/Footer';

interface BannerType {
  updatedAt: string;
  active: boolean;
  text: string;
  linkText: {
    label: string;
    href: string;
  };
}

export default function Layout({
  children,
  navigation: NavigationComponent,
  navigationProps = {},
  withoutFooter = false,
  bannerInfo,
  footerProps = {},
}: PropsWithChildren & {
  navigation?: typeof DefaultNavigation;
  navigationProps?: NavigationType;
  withoutFooter?: boolean;
  bannerInfo?: BannerType;
  footerProps?: FooterType;
}) {
  const Navigation = useMemo(() => NavigationComponent || DefaultNavigation, [NavigationComponent]);
  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: true,
  });
  const { setScrolled } = useContext(NavigationContext);

  useEffect(() => {
    setScrolled(!inView);
  }, [inView, setScrolled]);

  return (
    <>
      <div className="banner-observer" ref={ref}>
        {bannerInfo?.active && <Banner bannerInfo={bannerInfo} />}
      </div>
      <Navigation {...navigationProps} fixed={!inView} />
      <div ref={ref} />
      <main>{children}</main>
      {!withoutFooter && <Footer {...footerProps} />}
    </>
  );
}
