import styled from 'styled-components';

import SwordAscend from '@/public/assets/logo/ascend_logo.svg';

interface ButtonType {
  onClick: Function;
}

const LogoWrapper = ({ onClick }: ButtonType) => {
  return (
    // @ts-ignore
    <Logo onClick={onClick}>
      <SwordAscend />
    </Logo>
  );
};

export default LogoWrapper;

const Logo = styled.button<ButtonType>`
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
  height: 22px;
  cursor: pointer;

  svg {
    height: 100%;
    width: 97px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    svg {
      width: 119px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    text-align: left;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    svg {
      height: 28px;
      width: 167px;
    }
  }
`;
