import React, { useMemo } from 'react';
import Image from 'next/image';
import Slider from 'react-slick';
import RightArrow from '../../../public/assets/shapes/arrow_right.svg';
import theme from '@/utils/styles-variables';
import {
  SliderContainer,
  SliderCicleOne,
  SliderCicleTwo,
  SliderCard,
  SliderImage,
  PrevArrow,
  NextArrow,
} from './styles';

import { getImgSrcWorkaround, getImgData } from '@/utils/images';

type VanueSliderType = {
  content: {
    data: [
      {
        attributes: {
          attributes: string;
          alternativeText: string;
        };
      },
    ];
  };
  smallButton?: boolean;
  largeButton?: boolean;
  showShapes?: boolean;
  darkTheme?: boolean;
};

const VenueSlide: React.FC<VanueSliderType['content']['data'][0]> = (slide) => {
  const image = useMemo(() => getImgData(slide), [slide]);

  return (
    <SliderCard>
      <SliderImage>
        <Image
          src={getImgSrcWorkaround(image.url)}
          alt={image.alternativeText || image.name}
          fill
          sizes="100vw"
          priority
        />
      </SliderImage>
    </SliderCard>
  );
};

const VenueSlider = ({
  content,
  darkTheme,
  smallButton = false,
  largeButton = true,
  showShapes = true,
}: VanueSliderType) => {
  const settings = {
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    nextArrow: (
      <NextArrow
        $smallButton={smallButton}
        $largeButton={largeButton}
        $darkTheme={darkTheme}
        aria-label="Next image"
        type="button"
      >
        <RightArrow aria-hidden="true" />
      </NextArrow>
    ),
    prevArrow: (
      <PrevArrow
        $smallButton={smallButton}
        $largeButton={largeButton}
        $darkTheme={darkTheme}
        aria-label="Previous image"
        type="button"
      >
        <RightArrow aria-hidden="true" />
      </PrevArrow>
    ),
    responsive: [
      {
        breakpoint: theme.breakpoints.max_sm,
        settings: {
          centerPadding: '40px',
          centerMode: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <SliderContainer>
      {showShapes && (
        <>
          <SliderCicleOne />
          <SliderCicleTwo />
        </>
      )}
      <Slider {...settings}>
        {content?.data?.map((slide: any) => <VenueSlide key={slide.id} {...slide} />)}
      </Slider>
    </SliderContainer>
  );
};

export default VenueSlider;
