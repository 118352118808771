import dynamic from 'next/dynamic';
import React from 'react';

import Wrapper from './Wrapper';
import Title from './Title';
import Companies from './Companies';

const Marquee = dynamic(() => import('./StyledMarquee'), {
  loading: () => <span />,
  ssr: false,
});

const OneWayContainer = dynamic(() => import('./OneWayContainer'), {
  loading: () => <span />,
  ssr: false,
});

type CompaniesSliderProps = {
  content: {
    title: string;
    companies: any[];
  };
  full?: boolean;
};

const CompaniesSlider = ({ content, full }: CompaniesSliderProps) => {
  if (!content || !content.companies || !content.companies.length) return null;

  const { title, companies } = content;

  return (
    <Wrapper $full={full} data-testid="wrapper">
      <OneWayContainer full={full && !title} data-testid="oneWayContainer">
        {title && <Title>{title}</Title>}

        <Marquee full={full}>
          <Companies list={companies} />
        </Marquee>
      </OneWayContainer>
    </Wrapper>
  );
};

export default CompaniesSlider;
