import { useContext } from 'react';
import Link from 'next/link';

import styled, { css } from 'styled-components';
import { RedButton } from '@/components/core/Button';

import NavigationContext from '@/contexts/navigation';
import ListItem from './Item';

import { Info } from '@/components/homepage/Countdown/types';
import { useUrlAttributes } from '@/utils/useUrlAttributes';

type MenuListType = {
  canJoin?: boolean;
  info: Info;
  navigateToId: Function;
};

const MenuList = ({ canJoin, info, navigateToId }: MenuListType) => {
  const { isOpen } = useContext(NavigationContext);
  const urlAttributes = useUrlAttributes(info.link);

  return (
    <NavListWrapper $isOpen={isOpen}>
      <NavListBox>
        <NavList>
          <ListItem label="Event" url="/#event" onClick={() => navigateToId('event')} />
          <ListItem label="Venue" url="/#venue" onClick={() => navigateToId('venue')} />
          <ListItem label="Agenda" url="/#agenda" onClick={() => navigateToId('agenda')}></ListItem>
          <ListItem label="More Info" url="/#more-info" onClick={() => navigateToId('more-info')} />

          {canJoin && (
            <ActionItems>
              <ListItem
                url={info.link}
                label={info.joinButtonLabel}
                component={RedButton}
                extraClass="request-demo"
                button
                href={info.link}
                forwardedAs={Link}
                {...urlAttributes}
              />
            </ActionItems>
          )}
        </NavList>
      </NavListBox>
    </NavListWrapper>
  );
};

type NavListWrapperType = {
  $isOpen: Boolean;
};

const NavListWrapper = styled.div<NavListWrapperType>`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    pointer-events: none;

    ${(props) =>
      props.$isOpen &&
      css`
        pointer-events: all;
        opacity: 1;
        z-index: 100;
      `}
  }
`;

const NavListBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  border-top: 1px solid rgba(31, 34, 44, 0.1);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: flex-start;
    padding-top: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 145px;
    position: relative;

    &:before {
      position: fixed;
      content: '';
      bottom: 125px;
      left: 0;
      right: 0;
      height: 20px;
      background-color: white;
      z-index: 10;
    }

    &:after {
      position: fixed;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 125px;
      background-color: white;
      z-index: 10;
    }
  }
`;

const NavList = styled.div`
  display: inline-flex;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 140px;
  }
`;

const ActionItems = styled.div`
  display: contents;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 100;
  }
`;

export default MenuList;
