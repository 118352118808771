import styled, { css } from 'styled-components';

import { Title, UpperText } from '@/components/core/Title';
type WrapperType = { $centered?: boolean; $sizeLarge: string };

export const Wrapper = styled.div<WrapperType>`
  padding-bottom: 80px;
  max-width: ${(props) => props.$sizeLarge};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 64px;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 40px;
  }

  ${(props) =>
    props.$centered &&
    css`
      margin: 0 auto;
      max-width: 850px;
      text-align: center;
    `}
`;

export const Content = styled.div`
  min-width: 448px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    min-width: unset;
    width: 100%;
  }
`;

export const UpperLabel = styled(UpperText)`
  opacity: 1;
  letter-spacing: 2px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 24px;
  }
`;

export const CustomTitle = styled(Title)`
  font-size: 45px;
  line-height: 56px;
  padding-bottom: 32px;
  font-family: ${({ theme }) => theme.fonts.title};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const TextArrowButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  font-family: ${(props) => props.theme.fonts.title};
  color: ${(props) => props.theme.colors.red};
  padding-right: 20px;
  position: relative;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
  margin-top: 32px;
  width: max-content;

  &:before {
    position: absolute;
    content: '';
    width: 5px;
    height: 8px;
    top: 9px;
    right: 3px;
    background: url('/assets/chevron-red.svg') no-repeat center center;
    transition: all 0.3s ease;
  }

  &:hover {
    color: ${(props) => props.theme.colors.grey.dark};
    &:before {
      transform: translateX(3px);
      background: url('/assets/chevron-black.svg') no-repeat center center;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 24px;
  }
`;
