import Modal from '@/components/shared/Modal/Modal';
import { CloseButton, IconClose, Video } from './styles';

type VideoPlayerModalType = {
  modalIsOpen?: boolean;
  closeModal?: any;
  videoURL?: string;
};

const VideoPlayerModal = ({ modalIsOpen, closeModal, videoURL }: VideoPlayerModalType) => (
  <>
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
      {modalIsOpen && (
        <CloseButton aria-label="Close" type="button" onClick={closeModal} data-testid="closeModal">
          <IconClose />
        </CloseButton>
      )}
      <Video controls autoPlay playsInline data-testid="player">
        <source src={videoURL} />
      </Video>
    </Modal>
  </>
);

export default VideoPlayerModal;
