import { memo } from 'react';
import styled, { css } from 'styled-components';

import { MarkdownRichText } from '@/components/shared';
import { wysiwyg } from '../Accordion/wysiwyg';

const getSizeStyles = ({ $size }) => {
  switch ($size) {
    case 'lg': {
      return css`
        font-size: 18px;
        line-height: 32px;
        font-weight: var(--body-font-weight, 500);

        @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
          font-size: 16px;
          line-height: 28px;
        }
      `;
    }
    case 'sm': {
      return css`
        font-size: 14px;
        line-height: 24px;
        font-weight: var(--body-font-weight, 500);
      `;
    }
    default: {
      return css`
        font-size: 16px;
        line-height: 28px;
        font-weight: var(--body-font-weight, 500);
      `;
    }
  }
};

const styles = css`
  ${(props) =>
    props.$richText &&
    css`
      ${wysiwyg}
    `};

  ${(props) =>
    props.$multiLine &&
    css`
      display: grid;
      gap: 15px;
    `};

  ${(props) => getSizeStyles(props)};
`;

const StyledParagraph = styled.p`
  ${styles}
`;

function Paragraph({
  className = null,
  children,
  isMarkdown = true,
  isMultiLine = false,
  markdownProps = {},
  richText = false,
  size = 'md',
  ...rest
}) {
  return (
    <StyledParagraph
      className={className}
      $size={size}
      $richText={richText}
      $multiLine={isMultiLine}
      {...rest}
    >
      {isMarkdown ? <MarkdownRichText {...markdownProps}>{children}</MarkdownRichText> : children}
    </StyledParagraph>
  );
}

export default memo(Paragraph);
