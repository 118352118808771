import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
`;

type loadingType = {
  loading?: boolean;
  loadingColor?: string;
};

const Loading = ({ loading, loadingColor }: loadingType) => {
  if (!loading) return null;
  return (
    <LoaderWrapper>
      <PulseLoader color={loadingColor} size={8} />
    </LoaderWrapper>
  );
};

export default Loading;
