import { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import cn from 'classnames';

type MarqueeType = {
  className?: string;
  children?: ReactElement;
  full?: boolean;
  speed?: number;
  invertedScroll?: boolean;
  height?: number;
};

const Marquee = ({ className, children, full, speed, invertedScroll, height }: MarqueeType) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>();
  const [animate, setAnimate] = useState(false);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.scrollWidth);
    }
  }, [children]);

  useLayoutEffect(() => {
    if (wrapperRef.current && width) {
      const handleResize = () => {
        if (wrapperRef.current && width > wrapperRef.current.clientWidth) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [width]);

  return (
    <Wrapper
      ref={wrapperRef}
      className={cn(className, { static: !animate })}
      $animate={animate}
      $height={height}
    >
      {!width || !animate ? (
        <Content ref={contentRef} $animate={animate} $full={full}>
          {children}
        </Content>
      ) : (
        <AnimatedContent
          animate={{
            x: invertedScroll ? [-width, 0] : [0, -width],
            transition: {
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: speed ?? 30,
                ease: 'linear',
              },
            },
          }}
        >
          {children}
          {children}
        </AnimatedContent>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $animate?: boolean; $height?: number }>`
  position: relative;
  overflow: hidden;
  height: 48px;

  ${(props) =>
    !props.$animate &&
    css`
      &:after,
      &:before {
        display: none;
      }
      mask-image: none !important;
    `};

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `}
`;

const contentCSS = css`
  position: absolute;
  float: left;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Content = styled.div<{ $animate?: boolean; $full?: boolean }>`
  ${contentCSS}

  ${(props) =>
    !props.$animate &&
    css`
      padding-right: 36px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        left: 50%;
        transform: translateX(-50%);
        padding-right: 24px;
        padding-left: 24px;
      }
    `};
`;

const AnimatedContent = styled(motion.div)`
  ${contentCSS};
`;

export default Marquee;
