import styled, { css } from 'styled-components';

type WrapperType = {
  $full?: boolean;
};

const Wrapper = styled.div<WrapperType>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 125px;
  overflow: hidden;
  padding-top: 130px;
  z-index: 1;

  ${(props) =>
    props.$full &&
    css`
      height: unset;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 136px;
    align-items: unset;
    padding-top: 64px;
    ${(props) =>
      props.$full &&
      css`
        height: unset;
      `};
  }
`;

export default Wrapper;
