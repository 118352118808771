import { StyledAs } from '@/types/styled-components';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

type stylesProps = {
  $type?: 'primary' | 'secondary';
};

const styles = css<stylesProps>`
  ${({ theme, $type }) => css`
    font-family: ${theme.fonts.title};
    font-size: 55px;
    line-height: 64px;
    font-weight: var(--title-font-weight, 700);
    padding-bottom: 32px;
    text-transform: var(--title-text-transform, none);

    ${$type === 'primary'
      ? css`
          color: var(--title-color-primary, ${theme.colors.grey.dark});
        `
      : css`
          color: var(--title-color, ${theme.colors.white});
        `}

    b,
    strong {
      color: ${theme.colors.red};
    }

    @media (max-width: ${theme.breakpoints.max_md}px) {
      font-size: 45px;
      line-height: 56px;
      padding-bottom: 24px;
    }

    @media (max-width: ${theme.breakpoints.max_sm}px) {
      font-size: 25px;
      line-height: 32px;
    }
  `}
`;

const H1 = styled.h1<stylesProps>`
  ${styles}
`;
const H2 = styled.h2<stylesProps>`
  ${styles}
`;
const H3 = styled.h3<stylesProps>`
  ${styles}
`;
const H4 = styled.h4<stylesProps>`
  ${styles}
`;

interface TitleProps extends StyledAs {
  className?: string;
  type?: 'primary' | 'secondary';
}

function Title({ as = 'h2', children, className, type, ...rest }: PropsWithChildren<TitleProps>) {
  switch (as) {
    case 'h1':
      return (
        <H1 className={className} $type={type} {...rest}>
          {children}
        </H1>
      );
    case 'h2':
      return (
        <H2 className={className} $type={type} {...rest}>
          {children}
        </H2>
      );
    case 'h3':
      return (
        <H3 className={className} $type={type} {...rest}>
          {children}
        </H3>
      );
    case 'h4':
      return (
        <H4 className={className} $type={type} {...rest}>
          {children}
        </H4>
      );
    default:
      return (
        <H2 className={className} $type={type} {...rest}>
          {children}
        </H2>
      );
  }
}

export default Title;
export { H1, H2, H3, H4, styles };
