import { Container, Section } from '@/components/core';
import EventContext from '@/contexts/event';
import { useContext, useState } from 'react';
import {
  Header,
  UpperLabel,
  CustomTitle,
  VideoContainer,
  ContentVideo,
  VideoOverlay,
  ActionVideo,
  ButtonPlay,
  CustomTitleVideo,
} from './styles';

import { TagContent } from '@/components/hackathon/TagContent';
import { useReducedMotion } from '@/utils/useReducedMotion';
import { getImgSrcWorkaround } from '@/utils/images';
import { VideoPlayerModal } from '@/components/shared';

import Play from '@/public/assets/ic_play.svg';

const Video = ({
  darkTheme,
  withTagNames = false,
}: {
  darkTheme?: boolean;
  withTagNames?: boolean;
}) => {
  const { video } = useContext(EventContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const prefersReducedMotion = useReducedMotion();

  if (!video) return null;

  const { title, videoTitle, upperLabel, previewVideo, buttonLabel, videoUrl } = video;

  const showHeader = title || upperLabel;

  return (
    <Section
      paddingMd={{ top: darkTheme ? '0' : 136, bottom: darkTheme ? undefined : 136 }}
      paddingSm={{ top: darkTheme ? '0' : 104, bottom: darkTheme ? undefined : 104 }}
      paddingXs={{ top: darkTheme ? '0' : 64, bottom: darkTheme ? undefined : 64 }}
      color={'transparent'}
    >
      <Container>
        {showHeader && (
          <Header>
            {upperLabel && (
              <UpperLabel $type="primary" as="p">
                {upperLabel}
              </UpperLabel>
            )}
            {title && (
              <CustomTitle
                type="primary"
                as={withTagNames ? TagContent : undefined}
                tagName="h2"
                forwardedAs="h2"
              >
                {title}
              </CustomTitle>
            )}
          </Header>
        )}
        <ContentVideo>
          <VideoOverlay />
          <ActionVideo>
            <CustomTitleVideo
              type="secondary"
              as={withTagNames ? TagContent : undefined}
              tagName="h4"
              forwardedAs="h4"
            >
              {videoTitle}
            </CustomTitleVideo>
            <ButtonPlay big onClick={() => setModalIsOpen(true)}>
              <Play />
              {buttonLabel}
            </ButtonPlay>
          </ActionVideo>
          <VideoContainer autoPlay={!prefersReducedMotion} playsInline loop muted>
            <source src={getImgSrcWorkaround(previewVideo?.url ? previewVideo.url : video)} />
          </VideoContainer>
        </ContentVideo>
        <VideoPlayerModal
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          videoURL={videoUrl}
        />
      </Container>
    </Section>
  );
};

export default Video;
