import Link from 'next/link';
import ArrowRight from '@/public/assets/arrow-right.svg';
import { ItsTimeWrapper, ItsTimeContent, ItsTimeText, ItsTimeLink, ItsTimeBorder } from './styles';

type ItsTimeTypes = {
  successLabel: string;
  link?: string;
  joinButtonLabel?: string;
};

const ItsTime = ({ successLabel, link, joinButtonLabel }: ItsTimeTypes) => {
  if (!link || !joinButtonLabel) return null;

  return (
    <ItsTimeWrapper>
      <ItsTimeBorder>
        <ItsTimeContent>
          <ItsTimeText>{successLabel}</ItsTimeText>
          <Link href={link} target="_blank">
            <ItsTimeLink>
              {joinButtonLabel} <ArrowRight />
            </ItsTimeLink>
          </Link>
        </ItsTimeContent>
      </ItsTimeBorder>
    </ItsTimeWrapper>
  );
};

export default ItsTime;
