import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import Loading from './ButtonLoader';
import ExternalLink, { ExternalLinkProps } from '../ExternalLink';

type ButtonType = PropsWithChildren & {
  href?: ExternalLinkProps['href'];
  onClick?: () => void;
  className?: string;
  ref?: any;
  type?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  disabled?: boolean;
  height?: string;
  big?: boolean;
  small?: boolean;
  loadingColor?: string;
};

type ButtonStyleType = {
  $big?: boolean;
  $small?: boolean;
  $loading?: boolean;
};

export const buttonCss = css`
  height: 40px;
  padding: 0 16px;
  border-radius: 100px;
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  text-align: center;
  text-transform: var(--button-text-transform, none);
`;

const ButtonContent = styled.span`
  align-items: center;
  display: inline-flex;
`;

const ButtonStyle = styled.button<ButtonStyleType>`
  ${buttonCss};
  align-items: center;
  display: inline-flex;
  justify-content: center;

  ${(props) =>
    props.$big &&
    css`
      height: 48px;
      padding: 12px 32px;
      font-size: 16px;
    `}

  ${(props) =>
    props.$small &&
    css`
      padding: 0 11px;
      height: 50px;
    `}

  ${(props) =>
    props.$loading &&
    css`
      color: transparent;
      pointer-events: none;
    `}
`;

const Button = React.forwardRef(
  ({ big, href, loading, children, loadingColor, type, ...rest }: ButtonType, ref) => {
    return (
      <ButtonStyle
        ref={ref}
        $loading={loading}
        $big={big}
        {...rest}
        {...(href
          ? { as: ExternalLink as React.ComponentType<any>, href }
          : { type: type || 'button' })}
      >
        <Loading loading={loading} loadingColor={loadingColor} />
        <ButtonContent>{children}</ButtonContent>
      </ButtonStyle>
    );
  },
);

Button.displayName = 'Button';

export default Button;
