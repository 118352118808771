import { useMemo } from 'react';
import { NextSeo, NextSeoProps } from 'next-seo';

import { getImgData } from '@/utils/images';
import getFavicons from '@/utils/getFavicons';

interface SEOType {
  favicons?: NextSeoProps['additionalLinkTags'];
  preview?: boolean;
  theme?: string;
  seo: {
    title: string;
    description: string;
    image: any;
  };
}

const FAVICON_VERSION = 3;

const SEO = ({ preview = false, seo, theme }: SEOType) => {
  const image = useMemo(() => getImgData(seo?.image), [seo]);

  if (!seo) {
    return null;
  }

  const { title, description } = seo;

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title: title,
        description: description,
        images: [
          {
            url: image?.url,
            width: image?.width,
            height: image?.height,
            alt: image?.alternativeText,
          },
        ],
        site_name: 'Sword Health',
      }}
      nofollow={preview}
      noindex={preview}
      robotsProps={{
        noarchive: preview,
      }}
      additionalLinkTags={getFavicons(FAVICON_VERSION, theme)}
    />
  );
};

export default SEO;
